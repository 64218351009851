<template>
  <div>
    <b-tabs content-class="mt-3" v-model="tab_index">
      <b-tab>
        <template #title>
          {{
            $getVisibleNames(
              "teaching.tempcompetenceunit",
              false,
              "Unidad de competencia"
            )
          }}
        </template>
        <SentenceForm
          :first_content="unit_competence.action"
          :second_content="unit_competence.content"
          :third_content="unit_competence.condition"
          :full_sentence="unit_competence.full_sentence"
          :verb="unit_competence.verb"
          third_content_comment
          @update="slotUpdateSentence"
        ></SentenceForm>
      </b-tab>
      <b-tab title="Detalles">
        <b-form-group
          v-if="matter && !matter.automatic_achievement_weighing"
          label-cols="0"
          label-cols-lg="3"
          label-size="sm"
          label="Ponderación:"
          label-for="input-weighing"
          :description="`Especifica el porcentaje de tributación para la ${$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}.`"
          class="m-0 p-0"
        >
          <b-form-input
            type="number"
            id="input-weighing"
            min="0"
            max="100"
            v-model="$v.unit_competence.weighing.$model"
            :state="validateState('weighing')"
            aria-describedby="input-weighing-feedback"
            size="sm"
          ></b-form-input>
          <div
            v-if="
              WeighingAvailable > 0 &&
              unit_competence.weighing != '' &&
              unit_competence.weighing < WeighingAvailable
            "
            class="mb-2 alert-student-weighing"
          >
            La ponderación disponible es de:
            <strong>{{ WeighingAvailable - unit_competence.weighing }}%</strong>
          </div>
          <b-form-invalid-feedback id="input-weighing-feedback"
            >Este campo es obligatorio y no debe exceder
            {{ WeighingAvailable }}%.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          label-cols="0"
          label-cols-lg="3"
          label-size="sm"
          label="Orden:"
          label-for="input-order"
          description="Puede especificar la posición o el orden."
          class="m-0 p-0"
        >
          <b-form-input
            type="number"
            id="input-order"
            v-model="$v.unit_competence.order.$model"
            :state="validateState('order')"
            aria-describedby="input-order-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-order-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group label-cols="0" label-cols-sm="5" class="label m-0">
          <template #label>
            <div class="weighing-label">
              <div class="label-content ml-1">
                Ponderación Equivalente:
                <span>
                  <InfoTooltip
                    class="info-icon"
                    :tooltip_text="`Utilizar una ponderación equivalente para los ${$getVisibleNames(
                      'teaching.ramicro',
                      false,
                      'RA Micro'
                    )}`"
                  >
                  </InfoTooltip
                ></span>
              </div>
            </div>
          </template>
          <div>
            <div class="d-flex">
              <div class="toggle-types noprint">
                <span
                  :class="{
                    'font-weight-bold':
                      unit_competence.automatic_achievement_weighing,
                  }"
                  >Sí</span
                >
                <b-button
                  class="check-type"
                  @click="
                    () => {
                      unit_competence.automatic_achievement_weighing =
                        !unit_competence.automatic_achievement_weighing;
                    }
                  "
                  variant="primary"
                >
                  <b-icon
                    icon="circle-fill"
                    class="icon-check"
                    :class="{
                      'icon-check-active':
                        !unit_competence.automatic_achievement_weighing,
                    }"
                    scale="0.7"
                  ></b-icon>
                </b-button>
                <span
                  :class="{
                    'font-weight-bold':
                      !unit_competence.automatic_achievement_weighing,
                  }"
                  >No</span
                >
              </div>
            </div>
          </div></b-form-group
        >
      </b-tab>
      <div
        class="row mt-2"
        :class="{ 'justify-content-end': isNaN(unit_competence.id) }"
      >
        <div class="col" style="text-align: left">
          <b-button
            v-if="show_delete_button && !isNaN(unit_competence.id)"
            class="mr-1"
            size="sm"
            variant="danger"
            @click="removeCompetenceUnit"
            >Eliminar</b-button
          >
        </div>
        <div class="d-flex text-aling-right">
          <b-button
            v-if="show_save_button"
            class="mr-3 ml-3"
            size="sm"
            @click="saveCompetenceUnit"
            >Guardar</b-button
          >
        </div>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";

import {
  required,
  minValue,
  // maxValue,
  //   numeric,
} from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "CompetenceUnitForm",
  mixins: [validationMixin],
  components: {
    SentenceForm: () => import("@/components/reusable/SentenceForm"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    CompetenceUnit: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          verb: null,
          action: "",
          content: "",
          condition: "",
          full_sentence: "",
          context: "",
          name: "",
          description: "",
          order: 1,
          weighing: 0,
          matter: this.matter_id,
          automatic_achievement_weighing: true,
          competence: null,
        };
      },
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      unit_competence: { ...this.CompetenceUnit },
      tab_index: 0,
      base_sentence_toggle: this.CompetenceUnit
        ? this.CompetenceUnit.content
          ? true
          : false
        : false,
    };
  },
  validations() {
    return {
      unit_competence: {
        name: {},
        description: {},
        // action: { required },
        // content: { required },
        // condition: { required },
        weighing: {
          required,
          minVlaue: minValue(0),
          isValid(value) {
            if (
              parseFloat(value) == 0 ||
              parseFloat(value) <= this.WeighingAvailable ||
              (this.matter && this.matter.automatic_achievement_weighing)
            )
              return true;
            else return false;
          },
          // maxVlaue: maxValue(this.WeighingAvailable),
        },
        order: { required, minVlaue: minValue(1) },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      competence_units: names.COMPETENCE_UNITS,
      matters: names.MATTERS,
    }),
    matter() {
      if (!this.matter_id) return null;
      return this.matters.find((x) => x.id == this.matter_id);
    },
    competenceUnits() {
      return this.competence_units.filter((x) => x.matter == this.matter_id);
    },
    WeighingAvailable() {
      let weighing = 100;
      this.competenceUnits.forEach((element) => {
        if (element.id != this.unit_competence.id) {
          weighing -= element.weighing;
        }
      });
      return weighing;
    },
  },
  methods: {
    validateState(key) {
      const { $dirty, $error } = this.$v.unit_competence[key];
      return $dirty ? !$error : null;
    },
    slotUpdateSentence(item) {
      this.unit_competence.verb = item.verb;
      this.unit_competence.action = item.first_content;
      this.unit_competence.content = item.second_content;
      this.unit_competence.condition = item.third_content;
      this.unit_competence.full_sentence = item.full_sentence;
      this.base_sentence_toggle = item.base_sentence_toggle;
    },
    removeCompetenceUnit() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "teaching.tempcompetenceunit",
          false,
          "Unidad de competencia"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_STUDY_COMPETENCE, this.unit_competence.id)
            .then(() => {
              if (this.matter_id)
                this.$restful
                  .Get(
                    `teaching/toggle_matter_automatic_achievement_weighing/?matter=${this.matter_id}`
                  )
                  .then(() => {
                    this.$store
                      .dispatch(names.FETCH_COMPETENCE_UNITS, {
                        matter__id: this.matter_id,
                        force: true,
                      })
                      .then(() => {
                        this.$emit("deleted", this.unit_competence);
                      });
                  });
            });
        }
      });
    },
    saveCompetenceUnit() {
      if (this.base_sentence_toggle) {
        if (
          this.unit_competence.action.trim() == "" ||
          this.unit_competence.content.trim() == "" ||
          this.unit_competence.condition.trim() == ""
        ) {
          this.tab_index = 0;
          return;
        }
        this.$v.unit_competence.$touch();
        if (this.$v.unit_competence.$anyError) {
          this.tab_index = 1;
          return;
        }
        if (isNaN(this.unit_competence.id)) this.createCompetenceUnit();
        else this.updateCompetenceUnit();
      } else {
        if (
          this.unit_competence.full_sentence ||
          this.unit_competence.full_sentence.trim() != ""
        ) {
          this.$v.unit_competence.$touch();
          if (this.$v.unit_competence.$anyError) {
            this.tab_index = 1;
            return;
          }
          if (isNaN(this.unit_competence.id)) this.createCompetenceUnit();
          else this.updateCompetenceUnit();
        } else this.tab_index = 0;
      }
    },
    createCompetenceUnit() {
      this.$store
        .dispatch(names.POST_STUDY_COMPETENCE, this.unit_competence)
        .then((response) => {
          toast(
            this.$getVisibleNames(
              "teaching.tempcompetenceunit",
              false,
              "Unidad de competencia"
            ) + " creado."
          );
          if (this.matter_id)
            this.$restful
              .Get(
                `teaching/toggle_matter_automatic_achievement_weighing/?matter=${this.matter_id}`
              )
              .then(() => {
                this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
                  matter__id: this.matter_id,
                  force: true,
                });
              });
          this.$emit("created", response);
        });
    },
    updateCompetenceUnit() {
      this.$store
        .dispatch(names.UPDATE_STUDY_COMPETENCE, this.unit_competence)
        .then((response) => {
          toast(
            this.$getVisibleNames(
              "teaching.tempcompetenceunit",
              false,
              "Unidad de competencia"
            ) + " actualizado."
          );
          if (
            this.institution &&
            this.institution.internal_use_id == "ciisa_uss" &&
            this.unit_competence.automatic_achievement_weighing
          )
            this.$restful
              .Get(
                `teaching/toggle_temp_competence_unit_automatic_achievement_weighing/?temp_competence_unit=${this.unit_competence.id}`
              )
              .then(() => {
                this.$store.dispatch(names.FETCH_STUDY_UNITS, this.matter_id);
              });
          this.$emit("updated", response);
        });
    },
  },
  created() {
    if (!this.base_sentence_toggle && this.user && this.user.user_data)
      this.base_sentence_toggle = this.user.user_data.base_sentence_create;
    if (isNaN(this.unit_competence.id)) {
      let order = 0;
      this.competenceUnits.forEach((x) => {
        if (order < x.order) order = x.order;
      });
      this.unit_competence.order = order + 1;
    }
  },
};
</script>

<style scoped>
.toggle-types {
  display: flex;
  float: right;
  padding: 0.3em 1.8em;
  border-radius: 3px;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.check-type-disabled {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ) !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-69%, -50%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -50%) !important;
}
.label-content {
  width: max-content;
}
.info-icon {
  position: relative;
  vertical-align: top;
  height: 12px;
  width: 12px;
}
.total-modules-label > .label-content {
  max-width: 7rem;
}
@media (max-width: 991px) {
  .info-icon {
    margin-left: 0;
  }
}
</style>

