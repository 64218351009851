var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-tabs',{attrs:{"content-class":"mt-3"},model:{value:(_vm.tab_index),callback:function ($$v) {_vm.tab_index=$$v},expression:"tab_index"}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.tempcompetenceunit", false, "Unidad de competencia" ))+" ")]},proxy:true}])},[_c('SentenceForm',{attrs:{"first_content":_vm.unit_competence.action,"second_content":_vm.unit_competence.content,"third_content":_vm.unit_competence.condition,"full_sentence":_vm.unit_competence.full_sentence,"verb":_vm.unit_competence.verb,"third_content_comment":""},on:{"update":_vm.slotUpdateSentence}})],1),_c('b-tab',{attrs:{"title":"Detalles"}},[(_vm.matter && !_vm.matter.automatic_achievement_weighing)?_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-cols":"0","label-cols-lg":"3","label-size":"sm","label":"Ponderación:","label-for":"input-weighing","description":`Especifica el porcentaje de tributación para la ${_vm.$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        )}.`}},[_c('b-form-input',{attrs:{"type":"number","id":"input-weighing","min":"0","max":"100","state":_vm.validateState('weighing'),"aria-describedby":"input-weighing-feedback","size":"sm"},model:{value:(_vm.$v.unit_competence.weighing.$model),callback:function ($$v) {_vm.$set(_vm.$v.unit_competence.weighing, "$model", $$v)},expression:"$v.unit_competence.weighing.$model"}}),(
            _vm.WeighingAvailable > 0 &&
            _vm.unit_competence.weighing != '' &&
            _vm.unit_competence.weighing < _vm.WeighingAvailable
          )?_c('div',{staticClass:"mb-2 alert-student-weighing"},[_vm._v(" La ponderación disponible es de: "),_c('strong',[_vm._v(_vm._s(_vm.WeighingAvailable - _vm.unit_competence.weighing)+"%")])]):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"id":"input-weighing-feedback"}},[_vm._v("Este campo es obligatorio y no debe exceder "+_vm._s(_vm.WeighingAvailable)+"%. ")])],1):_vm._e(),_c('b-form-group',{staticClass:"m-0 p-0",attrs:{"label-cols":"0","label-cols-lg":"3","label-size":"sm","label":"Orden:","label-for":"input-order","description":"Puede especificar la posición o el orden."}},[_c('b-form-input',{attrs:{"type":"number","id":"input-order","state":_vm.validateState('order'),"aria-describedby":"input-order-feedback","size":"sm"},model:{value:(_vm.$v.unit_competence.order.$model),callback:function ($$v) {_vm.$set(_vm.$v.unit_competence.order, "$model", $$v)},expression:"$v.unit_competence.order.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-order-feedback"}},[_vm._v("Este campo es obligatorio.")])],1),_c('b-form-group',{staticClass:"label m-0",attrs:{"label-cols":"0","label-cols-sm":"5"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"weighing-label"},[_c('div',{staticClass:"label-content ml-1"},[_vm._v(" Ponderación Equivalente: "),_c('span',[_c('InfoTooltip',{staticClass:"info-icon",attrs:{"tooltip_text":`Utilizar una ponderación equivalente para los ${_vm.$getVisibleNames(
                    'teaching.ramicro',
                    false,
                    'RA Micro'
                  )}`}})],1)])])]},proxy:true}])},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"toggle-types noprint"},[_c('span',{class:{
                  'font-weight-bold':
                    _vm.unit_competence.automatic_achievement_weighing,
                }},[_vm._v("Sí")]),_c('b-button',{staticClass:"check-type",attrs:{"variant":"primary"},on:{"click":() => {
                    _vm.unit_competence.automatic_achievement_weighing =
                      !_vm.unit_competence.automatic_achievement_weighing;
                  }}},[_c('b-icon',{staticClass:"icon-check",class:{
                    'icon-check-active':
                      !_vm.unit_competence.automatic_achievement_weighing,
                  },attrs:{"icon":"circle-fill","scale":"0.7"}})],1),_c('span',{class:{
                  'font-weight-bold':
                    !_vm.unit_competence.automatic_achievement_weighing,
                }},[_vm._v("No")])],1)])])])],1),_c('div',{staticClass:"row mt-2",class:{ 'justify-content-end': isNaN(_vm.unit_competence.id) }},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.show_delete_button && !isNaN(_vm.unit_competence.id))?_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.removeCompetenceUnit}},[_vm._v("Eliminar")]):_vm._e()],1),_c('div',{staticClass:"d-flex text-aling-right"},[(_vm.show_save_button)?_c('b-button',{staticClass:"mr-3 ml-3",attrs:{"size":"sm"},on:{"click":_vm.saveCompetenceUnit}},[_vm._v("Guardar")]):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }